.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  column-gap: 32px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  row-gap: 32px;
}

.fixedWrapper {
  margin-top: 150px;
  padding-left: 257px;
}

.categoryContainer {
  margin-bottom: 24px;
}

.menu {
  position: fixed;
  top: 150px;
  padding-top: 32px;
  height: calc(100vh - 150px);
  overflow: scroll;
  min-width: 225px;
  max-width: 225px;
  z-index: 2;
}

.tabTitle {
  margin-left: 10px;
  margin-bottom: 16px;
}

.horizontalBlock {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  column-gap: 16px;
}

.tabButton {
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 45px;
  max-height: 45px;
  padding: 8px 10px !important;
  font-size: 14px;
}

.selectedTabButton,
.tabButton:hover {
  background-color: var(--neutral-900);
}

@media screen and (max-width: 670px) {
  .menu {
    display: none;
  }

  .fixedWrapper {
    margin-top: 100px;
    padding-left: 0px;
  }
}