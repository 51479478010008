.container {
  margin-top: 150px;
  margin-bottom: 100px;
  padding: 128px 32px;
}

.loginContainer {
  position: fixed;
  top: 74px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.noPadButton {
  padding: 0px !important;
}

.errorBorder {
  border-color: var(--red) !important;
}

.errorContainer {
  align-items: flex-start;
  column-gap: 6px;
  margin-top: 4px;
  margin-bottom: 8px;
}

.errorText {
  color: var(--red);
  text-align: left;
  max-width: 350px;
}

.errorIcon {
  margin-top: 4px;
}

.loginWrapper {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: auto;
  row-gap: 8px;
  padding: 32px;
}

.signInWithGoogle {
  margin-top: 16px;
  width: 350px;
}

.signInWithGoogleLink {
  width: 100%;
}

.paragraph {
  text-align: center;
}

.signUpLinkContainer {
  margin-top: 16px;
}

.googleIcon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.separator {
  width: 100%;
  height: 1px;
}

.separatorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 16px 0px;
  column-gap: 16px;
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
}

.input {
  width: 100%;
}

.button {
  width: 100%;
  min-width: 350px;
}

.magicIcon {
  margin-right: 8px;
}

@media screen and (max-width: 670px) {
  .container {
    margin-top: 74px;
  }
}

@media screen and (max-width: 430px) {
  .button {
    min-width: 0px;
  }

  .signInWithGoogle {
    width: 100%;
  }

  .loginWrapper {
    align-self: stretch;
  }
}
