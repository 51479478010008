.defaults {
  padding: 12px;
  padding-right: 8px;
  font-size: 15px;
  border-radius: 6px;
  color: var(--neutral-100);
  border: none;
  border-width: 1px;
  border-style: solid;
  border-color: var(--neutral-800);
  background-color: var(--neutral-1000);
  outline: none;
  transition: ease 0.225s;
  width: fit-content;
}

.defaults:focus {
  border-color: var(--neutral-600);
}

.defaults::placeholder {
  color: var(--neutral-600);
}

.prefixText {
  color: var(--neutral-100);
}

.prefixTextInput {
  width: 62px;
  min-height: 25px !important;
  max-height: 25px !important;
  background-color: transparent;
  border-width: 0px;
  padding: 0px;
  margin: 0px;
  margin-left: 16px;
  font-size: 13px;
  height: 100% !important;
  pointer-events: none;
}

.noPadButton {
  padding: 8px !important;
  color: var(--neutral-600);
  max-height: none !important;
  min-height: 0px !important;
  pointer-events: all !important;
}

.noPadButton:hover {
  color: var(--neutral-400);
}

.buttonTooltip {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgba(24, 24, 24, 1) !important;
  padding: 8px 11px;
  font-size: 12px;
  font-family: "Inter Regular";
  border-radius: 4px;
}

@media screen and (max-width: 670px) {
  .big {
    font-size: 16px;
    padding: 18px;
  }

  .defaults {
    width: 100%;
  }
}

@media screen and (max-width: 430px) {
  .big {
    width: 100%;
  }
}
