.defaults {
  color: var(--neutral-500);
  font-size: 16px;
  line-height: 180%;
  margin: 0;
}

.radioButton {
    white-space: initial;
    padding: 32px 8px;
    max-height: none;
    min-height: 0px;
}

.checkbox {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--neutral-800);
    margin-right: 12px;
    justify-content: center;
    align-items: center;
}

.checkboxIcon {
    color: var(--neutral-1000);
}

.checkedCheckbox {
    background-color: var(--deep-sea);
}

.radioText {
    font-size: 13px !important;
    text-align: left;
}

.radioButtonContainer {
    display: flex;
    align-items: flex-start;
    max-width: 300px;
}