.defaults {
    min-width: 115px;
    max-width: 115px;
    min-height: 115px;
    max-height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.viewDefaults {
    background-color: var(--neutral-900);
    min-width: 115px;
    max-width: 115px;
    min-height: 115px;
    max-height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    object-fit: cover;
}

.small {
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;
}

.smallView {
    min-width: 33px;
    max-width: 33px;
    min-height: 33px;
    max-height: 33px;
}

.initials {
    color: var(--neutral-400);
}

.smallText {
    font-family: "Inter SemiBold";
    font-size: 14px !important;
}