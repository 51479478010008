.defaults {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  padding: 24px;
  border-color: var(--neutral-800);
}
