.alertContainer {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: flex-end;
    justify-content: center;
    padding: 24px;
    pointer-events: none;
    transition: all 0.65s ease;
    margin-bottom: -50px;
    opacity: 0;
}

.alertComponent {
    width: auto;
    padding: 10px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--neutral-800);
    background-color: var(--neutral-1000);
    pointer-events: all;
}

.alertParagraph {
    font-size: 13px;
    line-height: 17px;
    color: var(--neutral-400);
}

.slideDownFadeIn {
    margin-bottom: 0px !important;
    opacity: 1;
}