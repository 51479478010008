.defaults {
  position: fixed;
  z-index: 9999999999;
  min-width: 400px;
  max-width: 750px;
  min-height: 0px;
  max-height: 80vh;
  border-radius: 8px;
  overflow: hidden;
  background-color: var(--neutral-1000);
  border-width: 1px;
  border-style: solid;
  border-color: var(--neutral-800);
  transition: 1.1s all ease;
}

.animateUp {
  animation-name: animateUp;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes animateUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.animateDown {
  animation-name: animateDown;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes animateDown {
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    transform: translateY(20px);
    opacity: 0;
  }
}

.titleContainer {
  background-color: var(--neutral-1000);
  padding: 16px;
}

.separator {
  width: 100%;
  height: 1px;
}

.smallTitle {
  font-size: 15px !important;
}

.rightAligned {
  align-items: center;
  justify-content: flex-end;
}

.closeButton {
  transition: all 0.1s ease;
  color: var(--neutral-600);
}

.closeButton:hover {
  color: var(--neutral-400);
}

@media screen and (max-width: 430px) {
  .defaults {
    min-width: 100vw;
    max-width: 100vw;
    min-height: none;
    max-height: calc(100vh - 48px);
    border-radius: 0px;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
  }
  .hideInMobile {
    display: none;
  }

  .titleContainer {
    display: none;
  }

  .separator {
    display: none;
  }
}
