.containerDefaults {
  transition: ease 0.225s;
  border: none;
  cursor: pointer;
  display: flex;
  align-self: baseline;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0px !important;
  padding: 0px !important;
  min-height: 55px;
  max-height: 55px;
  border-radius: 6px;
}

.defaults {
  white-space: nowrap;
  transition: ease 0.225s;
  border: none;
  letter-spacing: -0.2px;
  cursor: pointer;
  font-family: "Inter Regular";
  font-size: 15px;
  border-width: 0px;
  border-radius: 6px;
  display: flex;
  align-self: baseline;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--neutral-400);
  padding: 16px 24px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  min-height: 55px;
  max-height: 55px;
  -webkit-tap-highlight-color: transparent;
}

.defaults:hover {
}

button:focus, 
button:active {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.linkElement {
  width: 100%;
}

.big {
  min-height: 65px;
  max-height: 65px;
  font-size: 18px;
  padding: 22px 32px;
}

.small {
  font-size: 14px;
  min-height: 35px;
  max-height: 35px;
  padding: 10px 10px;
}

.medium {
  min-height: 45px !important;
  max-height: 45px !important;
  padding: 12px 16px !important;
}

.bigContainer {
  min-height: 65px;
  max-height: 65px;
  font-size: 18px;
  padding: 22px 32px;
}

.smallContainer {
  font-size: 14px;
  min-height: 35px;
  max-height: 35px;
  padding: 10px 10px;
}

.mediumContainer {
  min-height: 45px !important;
  max-height: 45px !important;
}

.spinnerOverlay {
  position: absolute;
  pointer-events: none;
  background-color: var(--neutral-300);
  height: 100%;
  border-radius: 8px;
}

.disabled {
  color: var(--neutral-100) !important;
  opacity: 0.45;
  pointer-events: none;
  cursor: copy !important;
}

.linkDisabled {
  cursor: not-allowed !important;
}

.destructive {
  color: var(--red) !important;
}

.buttonTooltip {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgba(24, 24, 24, 1) !important;
    padding: 8px 11px;
    font-size: 12px;
    font-family: "Inter Regular";
    border-radius: 4px;
}

@media screen and (max-width: 670px) {
  .big {
    font-size: 16px;
    padding: 18px;
  }
}

@media screen and (max-width: 430px) {
  .big {
    width: 100%;
  }
}
