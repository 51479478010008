.defaults {
  cursor: pointer;
  transition: ease 0.225s;
  color: var(--shallow-sea);
  z-index: 150;
}

.big {
  font-size: 20px;
}

.small {
  font-size: 13px;
}

.underlined {
  text-decoration: underline !important;
}