.container {
  margin-top: 0px;
  padding-top: 80px;
  max-height: 100vh;
  background-color: var(--neutral-950);
}

.cellContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 24px;
}

.cellLink {
  width: 100%;
}

.cell {
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: var(--neutral-400);
  border-radius: 8px;
  padding: 12px 16px;
}

.link {
  color: var(--neutral-100);
}

.icon {
  margin-right: 8px;
}

.logo {
  min-width: 95px;
  max-width: 95px;
}

.verified {
  max-width: 22px;
  min-width: 22px;
  max-height: 22px;
  min-height: 22px;
}

.verifiedFill {
  max-width: 15px;
  min-width: 15px;
  max-height: 15px;
  min-height: 15px;
  background-color: var(--neutral-400);
}

.preview {
  width: 450px;
  border-radius: 16px;
  background-color: var(--neutral-1000);
  justify-content: flex-start;
  align-items: flex-start;
  overflow: scroll;
  transform: scale(0.9); /* Adjust the value to control the zoom level */
  transform-origin: center;
}

.contentContainer {
  width: 100%;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--neutral-800);
}

.noWrap {
  white-space: nowrap;
}

.difBg {
  background-color: var(--neutral-950);
}

.bottomButtonContainer {
  padding: 24px;
  width: 100%;
  background-color: var(--neutral-950);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--neutral-800);
}

.verified {
  margin-left: 8px;
  max-width: 22px;
  min-width: 22px;
  max-height: 22px;
  min-height: 22px;
}

.smallMenu {
  min-width: 14px;
  max-width: 14px;
  min-height: 14px;
  max-height: 14px;
}

.sectionIconLink {
  position: absolute !important;
  top: 16.5px;
  right: 55.5px;
}

.secondSectionIconLink {
  position: absolute !important;
  top: 16.5px;
  right: 88.5px;
}

.iconLink {
  border-radius: 4px !important;
  pointer-events: all;
  position: relative;
  z-index: 41;
  display: flex;
  align-items: center;
  column-gap: 4px;
  flex-direction: row !important;
  white-space: nowrap;
  padding: 0px;
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
}

.iconLink:hover {
  background-color: var(--neutral-900);
}

.sectionHandle {
  min-width: 16px !important;
  max-width: 16px !important;
  min-height: 16px !important;
  max-height: 16px !important;
  margin-left: 26px;
}

.fullWidth {
  width: 100%;
}

.iconButton {
  column-gap: 8px;
}

.rightContainer {
  width: 100%;
  justify-content: flex-end;
}

.darkerText {
  color: var(--neutral-500);
}

.previewContainer {
  background-color: var(--neutral-950);
  width: auto;
  padding: 64px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--neutral-800);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--neutral-800);
}

.userLink {
  white-space: nowrap;
  color: var(--neutral-400);
  font-size: 13px;
}

.previewContent {
}

.background {
  width: 120px;
  height: 180px;
  border-radius: 8px;
  background-color: var(--neutral-800);
  margin-bottom: 12px;
  align-items: center;
  justify-content: center;
}

.gradientBackground {
  background: linear-gradient(to bottom, var(--neutral-800), transparent);
}

.titleContainer {
  position: relative;
  z-index: 14;
  padding: 24px;
  background-color: var(--neutral-950);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--neutral-800);
  margin-bottom: -1px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.accordion {
}

.fiftyTwo {
  max-height: 52px;
  background-color: var(--neutral-950);
}

.linkCell {
  max-height: 52px;
  padding: 0px 15.5px 0px 20px;
  
  cursor: grab;
}

.fiftyTwo:nth-child(even) {
  background-color: var(--neutral-1000);
}

.accordionButton {
  padding: 20px !important;
  cursor: grab;
}

.accordionButton:hover {
  background-color: var(--neutral-900);
}

.mobileAccordionButton {
}

.mobileAccordionButton:hover {
  background-color: var(--neutral-1000);
}

.firstAccordionItem {
  border-top-width: 0px !important;
}

.accordionItem {
  position: relative;
  border-color: var(--neutral-800) !important;
  border-bottom-width: 0px !important;
  background-color: var(--neutral-1000) !important;
}
.accordionIcon {
  filter: brightness(0) !important; /* Decrease brightness to 0, making it black */
  transition: none !important;
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
}

.darkModeAccordionIcon {
  filter: brightness(0) invert(1) !important; /* Decrease brightness to 0, making it black */
}

.accordionPanel {
  padding: 0px 0px !important;
}

.fullHeight {
  height: 100%;
}

.content {
  height: 100vh;
  overflow: scroll;
  background-color: var(--neutral-1000);
}

.lightContent {
  background-color: var(--neutral-1000);
}

.tooltip {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgba(24, 24, 24, 1) !important;
  padding: 8px 11px;
  font-size: 12px;
  font-family: "Inter Regular";
  border-radius: 4px;
}

.categoryTitle {
  font-size: 15px;
}

.emptyDataContainer {
  width: 100%;
  height: 100%;
  padding: 48px 32px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--neutral-800);
}

.noPadButton {
  border-width: 0px;
  padding: 0px !important;
  min-height: 0px !important;
  max-height: none !important;
}

.linkContainer {
  height: auto;
  overflow: hidden;
}

.radioButton {
  padding: 10px 0px;
}

.smallRadioButton {
  padding: 10px 0px;
  padding-right: 4.5px;
}

.categoryRadioButton {
  position: absolute;
  top: 10.5px;
  left: 10.5px;
  z-index: 1;
  padding: 0px 0px;
  pointer-events: all;
}

.locationContainer {
  padding: 32px;
  padding-top: 0px;
  margin-top: -12px;
}

.profileContent {
  padding: 16px;
}

.mobileEditProfileButtonContainer {
  padding: 16px;
  width: 100%;
}

.mobileEditProfileButtonContainer > div {
  padding: 16px;
  width: 100%;
}

.handle {
  color: var(--neutral-100);
  margin-right: 4px;
}

.menuTitle {
  color: #fff;
  text-align: left;
  align-self: baseline;
}

.thumbnail {
  max-width: 18px;
  min-width: 18px;
  max-height: 18px;
  min-height: 18px;
  border-radius: 4px;
  margin-right: 10px;
  object-fit: cover;
}

.fullWidthButton {
  width: 100%;
}

.show670 {
  display: none;
}

.appearanceContainer {
  margin: 24px;
  width: auto;
}

.appearanceBlock {
  background-color: var(--neutral-950);
  padding: 24px;
  border: 1px solid var(--neutral-800);
  border-radius: 8px;
}

.solidButton {
  min-height: 50px;
  max-height: 50px;
  color: #fff !important;
  border-radius: 0px;
  border-color: #000 !important;
  background-color: #000 !important;
}

.roundedButton {
  min-height: 50px;
  max-height: 50px;
  border-radius: 8px;
  color: #fff !important;
  border-color: #000 !important;
  background-color: #000 !important;
}

.roundButton {
  min-height: 50px;
  max-height: 50px;
  color: #fff !important;
  border-radius: 50px;
  border-color: #000 !important;
  background-color: #000 !important;
}

.solidButton:hover {
  color: #fff !important;
  border-radius: 0px;
  border-color: #000 !important;
  background-color: #000 !important;
}

.roundedButton:hover {
  color: #fff !important;
  border-color: #000 !important;
  background-color: #000 !important;
}

.roundButton:hover {
  color: #fff !important;
  border-radius: 50px;
  border-color: #000 !important;
  background-color: #000 !important;
}

.outlinedButton {
  color: #fff !important;
  border-radius: 0px;
  border-color: #fff !important;
  background-color: transparent !important;
}

.roundedOutlinedButton {
  color: #fff !important;
  border-color: #fff !important;
  background-color: transparent !important;
}

.roundOutlinedButton {
  color: #fff !important;
  border-radius: 50px;
  border-color: #fff !important;
  background-color: transparent !important;
}

.outlinedButton:hover {
  color: #000 !important;
  border-radius: 0px;
  border-color: #fff !important;
  background-color: #fff !important;
}

.roundedOutlinedButton:hover {
  color: #000 !important;
  border-color: #fff !important;
  background-color: #fff !important;
}

.roundOutlinedButton:hover {
  color: #000 !important;
  border-radius: 50px;
  border-color: #fff !important;
  background-color: #fff !important;
}

.colorPickerContainer {
  width: auto;
}

.colorInput {
  min-height: 0px;
  max-height: none;
  padding: 8px;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
}

.colorBlock {
  padding: 0px !important;
  min-width: 25px !important;
  max-width: 25px !important;
  min-height: 25px !important;
  max-height: 25px !important;
  border-radius: 4px;
  box-shadow: 0.1em 0.2em 0.6em rgba(0, 0, 0, 0.225);
}

.smallTitle {
  font-size: 14px;
}

@media screen and (max-width: 670px) {
  .appearanceContainer {
    margin: 16px;
    width: auto;
  }

  .show670 {
    display: flex;
  }

  .hide670 {
    display: none;
  }

  .mobileEditProfileButtonContainer {
    padding-top: 0px;
  }

  .profileContent {
    padding: 0px;
  }

  .verified {
    margin-left: 6px;
    max-width: 18px;
    min-width: 18px;
    max-height: 18px;
    min-height: 18px;
  }

  .container {
    padding-top: 70px;
    margin-bottom: 0px;
    overflow: auto;
    max-height: none;
  }

  .titleContainer {
    padding: 16px;
  }

  .bottomButtonContainer {
    padding: 16px;
  }

  .previewContainer {
    display: none;
  }

  .content {
    overflow: auto;
  }
}

@media screen and (max-width: 1035px) {
  .container {
    flex-direction: column;
  }

  .previewContainer {
    display: none;
  }

  .bottomButtonContainer {
    border-bottom-width: 0px;
  }
  
  .content {
    height: auto;
  }
}
