.container {
    max-width: 800px;
}

.cellContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 24px;
}

.categoryTitle {
    text-align: center;
}

.cellLink {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

.paragraph {
    margin-top: 6px;
    margin-bottom: -7px;
    color: var(--neutral-400);
}

.bio {
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
}

.name {
    font-size: 20px;
}

.thumbnail {
    max-width: 20px;
    min-width: 20px;
    max-height: 20px;
    min-height: 20px;
    border-radius: 4px;
    margin-right: 10px;
    object-fit: cover;
}

.cell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    min-width: 100%;
    max-width: 100%;;
    padding: 22px;
    text-align: center;
    background-color: var(--neutral-950);
    justify-content: flex-start;
}

.threadsIcon {
    width: 18px;
    height: 18px;
    margin-top: -0.5px;
    margin-left: -1px;
    margin-right: 6.5px !important;
}

.link {
    align-items: center;
    display: flex;
    margin-left: 1px;
    color: var(--neutral-100);
}

.icon {
    margin-right: 8px;
}

.logo {
    min-width: 85px;
    max-width: 85px;
}

.shareButton {
    position: absolute;
    right: 32px;
    top: 32px;
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    padding: 0px;
    background-color: var(--neutral-900);
    z-index: 1;
}

.mapPin {
    margin-right: 5px;
    margin-top: -0.5px;
}

.testString {
    max-width: 100%;
}

.background {
    align-items: flex-start;
    min-height: 100vh;
}

.backgroundImage {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.verified {
    margin-left: 6px;
    max-width: 18px;
    min-width: 18px;
    max-height: 18px;
    min-height: 18px;
}

.verifiedFill {
    max-width: 15px;
    min-width: 15px;
    max-height: 15px;
    min-height: 15px;
    background-color: var(--neutral-400);
}

.userTooltip {
    color: rgba(255, 255, 255, 1) !important;
    background-color: rgba(24, 24, 24, 1) !important;
    padding: 8px 11px;
    font-size: 12px;
    font-family: "Inter Regular";
    border-radius: 4px;
  }

  @media screen and (max-width: 670px) {
    .shareButton {
        right: 16px;
        top: 16px;
    }
    .verified {
        margin-left: 6px;
        max-width: 18px;
        min-width: 18px;
        max-height: 18px;
        min-height: 18px;
    }
  }