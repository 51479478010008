.defaults {
}

.small {
  min-width: 8px;
  max-width: 8px;
  min-height: 8px;
  max-height: 8px;
}

.medium {
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
}

.large {
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
}