.defaults {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
}

.vertical {
  flex-direction: column;
}

.centerVertically {
  align-items: center;
}

.centerHorizontally {
  justify-content: center;
}

.padSmall {
  padding: 8px;
}

.padMedium {
  padding: 16px;
}

.padLarge {
  padding: 32px;
}

.gapSmall {
  row-gap: 8px;
  column-gap: 8px;
}

.gapMedium {
  row-gap: 16px;
  column-gap: 16px;
}

.gapLarge {
  row-gap: 32px;
  column-gap: 32px;
}
