.container {
    margin-top: 100px;
    margin-bottom: 64px;
  }
  
  .loginContainer {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .loginWrapper {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: auto;
    row-gap: 8px;
    padding: 32px;
  }

  .ctaButton {
    column-gap: 6px;
    padding-right: 18px;
  }
  
  .signInWithGoogle {
    margin-top: 16px;
    width: 350px;
  }
  
  .signInWithGoogleLink {
    width: 100%;
  }
  
  .paragraph {
    text-align: center;
  }
  
  .signUpLinkContainer {
    margin-top: 16px;
  }
  
  .googleIcon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  
  .separator {
    width: 100%;
    height: 1px;
  }
  
  .separatorContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 16px 0px;
    column-gap: 16px;
  }
  
  .form {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 16px;
  }
  
  .input {
    width: 100%;
  }
  
  .magicIcon {
    margin-right: 8px;
  }
  
  @media screen and (max-width: 670px) {
    .container {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .button {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 430px) {
    .signInWithGoogle {
      width: 100%;
    }
  
    .loginWrapper {
      align-self: stretch;
    }
  }
  