.defaults {
  white-space: nowrap;
  font-family: "Inter Bold";
  line-height: 130%;
  color: var(--neutral-100);
  margin: 0;
  font-size: 45px;
  letter-spacing: -1.2px;
}

@media screen and (max-width: 670px) {
  .defaults {
    font-size: 40px;
  }
}
