@import "~rc-drawer/assets/index.css";

:root {
  --neutral-100: rgb(16, 16, 16);
  --neutral-200: rgb(32, 32, 32);
  --neutral-300: rgb(48, 48, 48);
  --neutral-400: rgb(81, 81, 81);
  --neutral-500: rgb(159, 159, 159);
  --neutral-600: rgb(184, 184, 184);
  --neutral-700: rgb(200, 200, 200);
  --neutral-800: rgb(225, 225, 225);
  --neutral-900: rgb(243, 243, 243);
  --neutral-950: rgb(248, 248, 248);
  --neutral-1000: rgb(255, 255, 255);
  --deep-sea: #000;
  --shallow-sea: #171717;
  --blue: #1473e6;
  --gold: #f0e933;
  --red: rgb(255, 0, 0);
  --light-red: rgba(255, 0, 0, 0.1);
  --green: rgb(6, 194, 88);
  --light-green: rgba(6, 194, 88, 0.1);
}

/* Hide scrollbar for Chrome on Windows */
::-webkit-scrollbar {
  width: 0em; /* Adjust the width as per your preference */
  height: 0em;
}

::-webkit-scrollbar-track {
  background: transparent; /* Set the background color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set the color of the scrollbar thumb */
}

@font-face {
  font-family: 'Inter ExtraLight';
  src: url('/public/assets/typefaces/Inter/ExtraLight.ttf') format('truetype');
  font-weight: 100;
  font-style: 100;
}

@font-face {
  font-family: 'Inter Light';
  src: url('/public/assets/typefaces/Inter/Light.ttf') format('truetype');
  font-weight: 200;
  font-style: 200;
}

@font-face {
  font-family: 'Inter Thin';
  src: url('/public/assets/typefaces/Inter/Thin.ttf') format('truetype');
  font-weight: 300;
  font-style: 300;
}

@font-face {
  font-family: 'Inter Regular';
  src: url('/public/assets/typefaces/Inter/Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: 400;
}

@font-face {
  font-family: 'Inter Medium';
  src: url('/public/assets/typefaces/Inter/Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: 500;
}

@font-face {
  font-family: 'Inter SemiBold';
  src: url('/public/assets/typefaces/Inter/SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: 600;
}

@font-face {
  font-family: 'Inter Bold';
  src: url('/public/assets/typefaces/Inter/Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: 700;
}

@font-face {
  font-family: 'Inter ExtraBold';
  src: url('/public/assets/typefaces/Inter/ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: 800;
}

@font-face {
  font-family: 'Inter Black';
  src: url('/public/assets/typefaces/Inter/Black.ttf') format('truetype');
  font-weight: 900;
  font-style: 900;
}

@font-face {
  font-family: 'Space Grotesk Light';
  src: url('/public/assets/typefaces/Space_Grotesk/Light.ttf') format('truetype');
  font-weight: 200;
  font-style: 200;
}

@font-face {
  font-family: 'Space Grotesk Regular';
  src: url('/public/assets/typefaces/Space_Grotesk/Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: 400;
}

@font-face {
  font-family: 'Space Grotesk Medium';
  src: url('/public/assets/typefaces/Space_Grotesk/Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: 500;
}

@font-face {
  font-family: 'Space Grotesk SemiBold';
  src: url('/public/assets/typefaces/Space_Grotesk/SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: 600;
}

@font-face {
  font-family: 'Space Grotesk Bold';
  src: url('/public/assets/typefaces/Space_Grotesk/Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: 700;
}

.body {
  font-family: 'Inter Regular';
  background-color: var(--neutral-1000);
}

html {
  background-color: var(--neutral-1000);
}

.drawer {
  
}

.drawer-open {
  
}

a {
  text-decoration: none;
}

.darkMode {
  --neutral-1000: rgb(16, 16, 16);
  --neutral-950: rgb(20, 20, 20);
  --neutral-900: rgb(24, 24, 24);
  --neutral-800: rgb(48, 48, 48);
  --neutral-700: rgb(159, 159, 159);
  --neutral-600: rgb(184, 184, 184);
  --neutral-500: rgb(200, 200, 200);
  --neutral-400: rgb(225, 225, 225);
  --neutral-300: rgb(243, 243, 243);
  --neutral-200: rgb(248, 248, 248);
  --neutral-100: rgb(255, 255, 255);
  --blue: #1e81fa;
  --red: rgb(218, 32, 32);
  --deep-sea: rgb(255, 255, 255);
  --shallow-sea: rgb(243, 243, 243);
}

.tooltip {
    color: rgba(255, 255, 255, 1) !important;
    background-color: rgba(24, 24, 24, 1) !important;
    padding: 8px 11px;
    font-size: 12px;
    font-family: "Inter Regular";
    border-radius: 4px;
    }
    