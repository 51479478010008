.container {
  padding: 64px;
  padding-top: 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--neutral-1000);
  z-index: 2;
}

.logoContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.rightContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}

.wrapper {
  padding: 24px 0px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.tag {
  color: var(--neutral-100);
  column-gap: 6px;
  padding: 1px 7px;
  min-height: 0px !important;
  height: 22px;
  font-size: 10px;
  font-family: "Inter SemiBold";
  border-color: var(--neutral-100);
  border-width: 1px;
  border-style: solid;
  border-radius: 50px;
  width:fit-content;
  margin-top: -2px;
  margin-bottom: -2px;
}

.miniH6 {
  font-size: 13px;
  margin-bottom: 6px;
}

.thirdy {
  width: 100%;
}

.borderedWrapper {
  margin-top: 32px;
  padding-top: 32px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--neutral-800);
}

.noPadButton {
  padding: 0px !important;
  max-height: none !important;
  min-height: 0px !important;
}

.logo {
  width: 85px;
  pointer-events: none;
}

.socialButton {
  min-height: 25px !important;
  max-height: 25px !important;
  min-width: 25px !important;
  max-width: 25px !important;
  padding: 0px !important;
}

.someWrapper {
  width: 100%;
}

@media screen and (max-width: 670px) {
  .container {
    padding: 0px 24px;
  }

  .wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 48px !important;
  }

  .borderedWrapper {
    margin-top: -24px;
  }

  .bottomText {
    text-align: center;
    width: 100%;
    margin-bottom: -24px;
    max-width: none;
  }

  .logo {
    min-width: 80px;
    max-width: 80px;
  }
}
