.line {
  width: 22px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--neutral-600);
  transition: all 0.225s ease;
}

.mobileButtonContainer {
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
  position: static !important;
}

.mobileButton {
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-right: -2px;
  flex-direction: column;
}

.fixedMobileButton {
    position: fixed;
    top: 9px;
    right: 12px;
    z-index: 15;
}

.mobileMenu {
  position: absolute;
  z-index: 1400;
  top: -50px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  background-color: var(--deep-sea);
  opacity: 0;
  transition: all 0.225s ease;
  pointer-events: none;
}

.ctaButton {
  column-gap: 6px;
  width: 100%;
}

.cleanButton {
  padding: 0px;
  min-height: 0px;
  max-height: none;
  border-width: 0px;
}

.mobileNavButtonContainer {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.maxWidthButton {
  width: 100%;
}

.mobileMenuWrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.animateNavLine:first-child {
  transform: rotate(45deg) translate(3.5px, 3.5px);
  background-color: var(--neutral-1000);
}

.animateNavLine:last-child {
  transform: rotate(-45deg) translate(3.5px, -3.5px);
  background-color: var(--neutral-1000);
}

.openMobileNavMenu {
  top: 0px;
  opacity: 1;
  pointer-events: all;
}

.accordions {
    display: flex;
    flex-direction: column;
}
