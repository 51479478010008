.defaults {
  min-width: 55px;
  max-width: 55px;
  min-height: 55px;
  max-height: 55px;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.buttonDefaults {
  padding: 0px !important;
}

.rounded {
  border-radius: 50%;
}

.big {
  min-width: 65px;
  max-width: 65px;
  min-height: 65px;
  max-height: 65px;
}

.small {
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px;
}