.contentContainer {
    background-color: var(--neutral-1000);
    padding: 24px;
  }
  
  .headerContainer {
    row-gap: 4px;
  }
  
  .whiteIcon {
    color: var(--neutral-1000);
  }
  
  .fullWidth {
    width: 100%;
  }
  
  .titleContainer2 {
    flex-direction: row;
    margin-bottom: -4px;
  }
  
  .iconContainer {
    padding-top: 1px;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
    border-radius: 50%;
    background-color: var(--blue);
  }
  
  .paddedContainer {
    padding: 24px;
    max-height: 50vh;
    overflow: scroll;
  }
  
  .messageText {
    color: var(--neutral-400);
  }
  
  .smallIconContainer {
    margin-left: -2px;
    padding-top: 1px;
    align-items: center;
    justify-content: center;
    min-width: 14px;
    max-width: 14px;
    min-height: 14px;
    max-height: 14px;
    border-radius: 50%;
    background-color: var(--blue);
  }
  
  .separator {
    width: 100%;
    height: 1px;
  }
  
  .buttonContainer {
    align-items: center;
    justify-content: flex-start;
    background-color: var(--neutral-1000);
    padding: 16px;
  }
  
  .buttonWrapper {
    align-items: center;
    justify-content: flex-end;
  }
  
  .squareButton {
    min-width: 45px;
    max-width: 45px;
    padding: 0px;
  }

  .thumbnail {
    max-width: 34px;
    min-width: 34px;
    max-height: 34px;
    min-height: 34px;
    border-radius: 50%;
    object-fit: cover;
    background-color: #fff;
  }

  .smallTitle {
    font-size: 15px;
  }

  .smallParagraph {
    margin-top: -4px;
    margin-bottom: 8px;
  }
  
  .ctaButton {
    width: 100%;
  }

  .rightContainer {
    width: 100%;
    justify-content: flex-end;
  }

  .shareButton {
    font-family: "Inter SemiBold";
    text-align: left;
    justify-content: flex-start;
    color: var(--neutral-400);
    column-gap: 8px;
    width: 100%;
    padding: 10px;
    min-height: 0px;
    max-height: none;
  }

  .linkShareButton {
    font-family: "Inter Regular";
    border: 1px solid var(--neutral-800);
    padding: 12px 14px;
  }
  
  .shareButton:hover {
    color: var(--neutral-100);
    background-color: var(--neutral-950);
  }
  
  @media screen and (max-width: 430px) {
    .hideInMobile {
      display: none;
    }
  
    .separator {
      display: none;
    }
  
    .buttonContainer {
      padding: 24px;
      padding-top: 0px !important;
    }
  }
  
  @media screen and (max-width: 490px) {
    .hideInMobile2 {
      display: none;
    }
  
    .fullWidthButton {
      min-width: 100%;
      max-width: 100%;
      width: 100%;
    }
  }
  