.defaults {
    padding: 16px 18px;
    font-size: 15px;
    border-radius: 6px;
    color: var(--neutral-100);
    border: none;
    border-width: 1px;
    border-style: solid;
    border-color: var(--neutral-800);
    background-color: var(--neutral-900);
    outline: none;
    transition: ease 0.225s;
    align-self: baseline;
    min-height: 55px;
    max-height: 55px;
    width: auto;
  }
  
  .defaults:focus {
    border-color: var(--deep-sea);
  }
  
  .defaults::placeholder {
    color: var(--neutral-600);
  }
  
  .big {
    font-size: 18px;
    padding: 18px 22px;
    min-height: 65px;
    max-height: 65px;
  }
  
  .small {
    padding: 12px 14px;
    min-height: 45px;
    max-height: 45px;
  }

  .prefixText {
    font-size: 15px;
    color: var(--neutral-100);
  }

  .bigPrefixText {
    font-size: 18px;
    color: var(--neutral-100);
  }

  .prefixTextInput {
    width: 100%;
    background-color: transparent !important;
    border-width: 0px !important;
    padding: 0px !important;
    margin: 0px;
    margin-top: -0.5px;
    margin-left: 0.5px;
    height: auto !important;
    min-height: 0px !important;
    max-height: none !important;
  }

  .smallPrefixTextInput {
    margin-top: -2px;
  }
  
  @media screen and (max-width: 670px) {
    .big {
      font-size: 16px;
      padding: 18px;
    }

    .defaults {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 430px) {
    .big {
      width: 100%;
    }
  }