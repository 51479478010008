.defaults {
    color: var(--neutral-500);
    font-size: 12px;
    line-height: 180%;
    margin: 0;
    padding: 5px 9px;
    border-radius: 12px;
    background-color: var(--light-green);
    color: var(--green);
  }
  
  .small {
    font-size: 13px !important;
    max-width: 600px;
  }
  
  .big {
    font-size: 20px !important;
    max-width: 600px;
  }
  
  b {
    color: var(--neutral-400);
  }
  