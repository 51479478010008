.defaults {
  font-family: "Inter SemiBold" !important;
  background-color: var(--deep-sea) !important;
  color: var(--neutral-1000) !important;
  border-width: 1px;
  border-style: solid;
  border-color: var(--deep-sea) !important;
}

.defaults:hover {
  color: var(--neutral-1000) !important;
  background-color: var(--shallow-sea) !important;
}

.bright {
  background-color: var(--shallow-sea) !important;
}

.disabled {
  cursor: not-allowed !important;
  background-color: var(--neutral-800) !important;
  color: var(--neutral-1000) !important;
  border-color: var(--neutral-800) !important;
}

.disabled:hover {
  background-color: var(--neutral-800) !important;
  color: var(--neutral-1000) !important;
}
