.defaults {
  white-space: nowrap;
  font-family: "Inter SemiBold";
  line-height: 100%;
  color: var(--neutral-100);
  margin: 0;
  font-size: 28px;
  letter-spacing: -0.8px;
}

@media screen and (max-width: 670px) {
  .defaults {
    font-size: 20px;
  }
}
