.container {
  max-width: 1200px;
  margin-top: 64px;
  margin-bottom: 50px;
  padding: 128px 32px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.wrapper {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.ctaInputError {
  border-color: var(--red) !important;
}

.errorIcon {
  min-width: 14px;
  max-width: 14px;
  min-height: 14px;
  max-height: 14px;
}

.errorContainer {
  justify-content: flex-start;
  max-width: 240px;
  column-gap: 4px;
  margin-top: 8px;
}

.errorMessage {
  white-space: nowrap;
  color: var(--red);
}

.autoWidth {
  width: auto;
  align-items: flex-start;
}

.noPadButton {
  padding: 0px !important;
  min-height: 0px;
  max-height: none;
  color: var(--neutral-100);
}

.rightContainer {
  width: 100%;
  justify-content: flex-end;
}

.mobileHeaderMedia {
  display: none;
  min-width: 100% !important;
  max-width: 100% !important;
  margin-bottom: 32px;
}

.subheading {
  color: var(--neutral-1000);
  border-width: 1px;
  border-style: solid;
  border-color: var(--neutral-1000);
  column-gap: 6px;
  padding: 6px 10px;
  padding-right: 6px;
  min-height: 0px !important;
  font-size: 13px;
  background-color: var(--neutral-100);
  border-radius: 50px;
  font-family: "Inter SemiBold";
}

.subheading:hover {
  color: var(--neutral-1000) !important;
  text-decoration: underline;
}

.headerMedia {
  min-width: 500px;
  max-width: 500px;
  border-radius: 16px;
}

.ctaButton {
  column-gap: 6px;
  padding-right: 18px;
}

.message {
  font-size: 20px;
  text-align: center;
  max-width: 600px;
}

.header {
  white-space: normal !important;
  max-width: 650px;
  font-size: 54px;
  text-align: center;
  background: linear-gradient(to bottom, var(--neutral-100), var(--neutral-400));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.circleContainer {
  width: 400px;
  max-height: 100px;
  overflow: hidden;
}

.curvedLine {
  width: 400px;
  height: 400px;
  background-color: transparent;
  border-width: 4px;
  border-style: solid;
  border-color: black;
  border-radius: 50%;
}

.circleOverlay {
  width: 400px;
  height: 50px;
  margin-top: -400px;
  background-image: linear-gradient(to bottom, transparent, white);
}

.separatorDot {
  color: var(--neutral-800);
  font-size: 10px !important;
}

.checkList {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
}

.initialPresentationLine {
  width: 4px;
  height: 250px;
  background-image: linear-gradient(to bottom, transparent, var(--neutral-100));
}

.lastPresentationLine {
  width: 4px;
  height: 250px;
  background-image: linear-gradient(to top, transparent, var(--neutral-100));
}

.presentationLine {
  width: 4px;
  height: 150px;
  background-color: var(--neutral-100);
}

.contentContainer {
  margin-top: -2px;
}

.presentationContainer {
  max-height: 38px;
  min-height: 38px;
}

.presentationIcon {
  min-width: 38px;
  min-height: 38px;
  max-width: 38px;
  max-height: 38px;
  margin-left: -17px;
  background-color: var(--neutral-100);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categoryTitle {
  margin-top: -6px !important;
  white-space: normal !important;
}

.sliderContainer {
  margin-top: -185px;
  max-width: 1200px;
  padding: 32px;
}

.emailInput {
  min-width: 250px;
}

.waitlistContainer {
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}

.orText {
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .desktopHeaderMedia {
    display: none;
  }

  .mobileHeaderMedia {
    display: flex !important;
  }

  .container {
    padding: 128px 32px;
  }

  .header {
    font-size: 38px !important;
    max-width: 450px !important;
  }
}

@media screen and (max-width: 670px) {
  .container {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .container {
    padding: 128px 24px;
  }

  .header {
    font-size: 32px !important;
    max-width: 450px !important;
  }
}

@media screen and (max-width: 430px) {
  .waitlistContainer {
    width: 100%;
    flex-direction: column !important;
    align-items: initial;
    row-gap: 24px;
  }

  .container {
    margin-top: -20px;
    padding: 128px 24px;
  }

  .ctaButton,
  .emailInput {
    width: 100%;
    min-width: 0px;
  }

  .orText {
    margin-top: -8px;
    margin-bottom: -8px;
  }

  .header {
    font-size: 28px !important;
  }
}
