.container {
  padding: 0px;
}

.wrapper {
  background-color: var(--neutral-900);
  width: 100%;
}

.titleWrapper {
  padding: 120px;
  padding-top: 184px;
  background-color: var(--neutral-1000);
}

.contentWrapper {
  max-width: 850px;
  padding: 100px 32px;
}

.largeTitle {
  margin-bottom: 24px;
}

.title {
  margin-top: 24px;
  margin-bottom: 16px;
}

.noPadButton {
  padding: 0px !important;
}

.errorBorder {
  border-color: var(--red) !important;
}

ul,
ol {
  color: var(--neutral-500);
  font-size: 16px;
  line-height: 180%;
}

.errorContainer {
  align-items: flex-start;
  column-gap: 6px;
  margin-top: 4px;
  margin-bottom: 8px;
}

.errorText {
  color: var(--red);
  text-align: left;
  max-width: 350px;
}

.errorIcon {
  margin-top: 4px;
}

.loginWrapper {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: auto;
  row-gap: 8px;
  padding: 32px;
}

.signInWithGoogle {
  margin-top: 16px;
  width: 350px;
}

.signInWithGoogleLink {
  width: 100%;
}

.paragraph {
  margin-bottom: 10px;
}

.signUpLinkContainer {
  margin-top: 16px;
}

.googleIcon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.separator {
  width: 100%;
  height: 1px;
}

.separatorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 16px 0px;
  column-gap: 16px;
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
}

.input {
  width: 100%;
}

.button {
  width: 100%;
}

.magicIcon {
  margin-right: 8px;
}

@media screen and (max-width: 670px) {
  .titleWrapper {
    padding-top: 164px;
  }
}

@media screen and (max-width: 430px) {
  .signInWithGoogle {
    width: 100%;
  }

  .loginWrapper {
    align-self: stretch;
  }
}
